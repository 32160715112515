import { MessageBox, Message, Loading } from 'element-ui'
import store from '@/store'
import axios from 'axios'
import {getCookie} from "./cookie"
/**
 * 使用：this.utils.handleconfirm(...)
 * 1.通用数据:this.utils.commonInfo.
 * 2. 操作提示； this.utils.handleconfirm(text ='此操作将永久删除该数据, 是否继续?', type='warning')
 * 3.返回表格操作时的页数 this.utils.handlePage(total, pageNum, pageSize=10)
 * 4.二进制流下载文件 this.utils.downloadFile(url, params={}, fileName="")
 * 5.文件上传的请求头 调用 this.utils.fileHeaders() 得到请求头返回值
 * 6.树形数组扁平化: this.utils.flagTree(arr)，得到扁平化后的数组
 * 7.两个数组的交集: this.utils.contain(array, compareArray)
 */
// 1. 通用数据
const commonInfo = function(){
  var info = {};
  info.stateToken = store.state.loginInfo.token;
  info.gettersToken = store.getters.loginInfo.token;
  info.stateUserName = store.state.loginInfo.username;
  info.gettersUserName = store.getters.loginInfo.username;
  info.stateNoHandle = store.state.loginInfo.noHandle;
  info.gettersNoHandle = store.getters.loginInfo.noHandle;
  return info;
}

// 2.操作提示
 const handleconfirm = (text ='此操作将永久删除该数据, 是否继续?', type='warning') => {
  // 这里 export后面不加 default，引用时：import {方法名} from js文件地址。因为每个文件常有多个方法，所以常用此方案
  // export后加 default，引用时：import 方法名 from js文件地址
  // 通过export方式导出，在导入时要加{ }，export default则不需要
  return MessageBox.confirm(text, '提示',{
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: type
  })
}

// 3.返回表格操作时的页数
const handlePage = (total, pageNum, pageSize=10) =>{
    // console.log(total) //total是数据还没删的所有总数
    if(total == 1){ //只有一条数据，删除后pageNum返回1
      return pageNum;
    } else {        //不止一条数据
      if(pageNum == Math.ceil(total/pageSize)){ //停留在分页的最后一页，
          if( total%pageSize == 1 ){   //总数=pageSize的倍数+1,删除后pageNum跳转到当前页的前一页
            return pageNum-1;
          } else {
            return pageNum;
          }
      } else {
        return pageNum
      }
    } //不止一条数据判断结束 
}

// 4.二进制流下载文件
const downloadFile = function(url, params={}){
  // if(arguments.length == 3){
  //   fileName = fileName;
  // }else if(arguments.length == 2 && typeof arguments[1] == "string"){
  //   fileName = arguments[1];params = {};
  // } else {
  //   fileName = "";
  // }
  axios({
    url: url,
    method:"post",
    data: params,
    responseType: 'arraybuffer'
  }).then(function(res,xls){
    console.log("返回的二进制流文件", res,xls )
    if(!res) return;
    var fileName = "";
    if(res.headers["content-disposition"]) {
      fileName = decodeURIComponent(res.headers["content-disposition"].split("=")[1]);
    } else {
      // https://wafer.vats.com.cn/vats-web/soft/downloadDevSoft/test.apk
      fileName = url.match(/\w+\.\w+$/g)[0];
    }
    let blob = new Blob([res.data], {type: 'application/vnd.ms-excel;charset=utf-8'}); 
    // 针对ie浏览器
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      //非ie浏览器
      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob); //常见下载的链接
      downloadElement.href = href;
      downloadElement.download = fileName; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
    }
  }).catch(function(error){
    console.log(error);
  });
}

// 5.文件上传时的请求头信息
let fileHeaders = function(){
  if( process.env.NODE_ENV == "development" ){
    return {'tc-token': 'none'}
  } else {
    return {}
  }
}

// 6.树形数组扁平化- https://blog.csdn.net/qq_43806488/article/details/119991726
const flagTree = function(arr){
  //声明一个空的数组
  let result = [];
  for (var item of arr) {
      //先深拷贝一层
      var res = JSON.parse(JSON.stringify(item)) 
      //删除所有的child
      delete res['children']
      // 然后给新数组添加
      result.push(res)
      // 如果当前child为数组并且长度大于0，才可进入flagTree()方法
      if (item.children instanceof Array && item.children.length > 0) { 
          result = result.concat(this.flagTree(item.children));
      }
  }
  return result
}

//7. 两个数组的交集
const contain = function(array, compareArray) {
  // 拿到最短数组的长度
  let minLength = Math.min(array.length, compareArray.length);
  let _minArray; //将成为最短长度的数组
  let _maxArray; //将成为最长长度的数组
      // 如果第一个数组为长度最短数组
  if (array.length == minLength) {
      _minArray = array;
      _maxArray = compareArray;
          // 如果第二个数组为长度最短数组
  } else {
      _minArray = compareArray;
      _maxArray = array;
  }
  let ret = [];
  let i = -1;
  while (++i < minLength) {
      // 如果最短数组里的成员在最长数组列表里存在，则说明这是交集成员
      if (_maxArray.indexOf(_minArray[i]) != -1) {
          ret.push(_minArray[i]);
      }
  }
  return ret;
}

export default{ handleconfirm, handlePage, downloadFile, fileHeaders, commonInfo, flagTree,contain }