// 设备管理
const apis = {
  device_nowMonitorList: "equipment/searchMonitor", //实时监控【列表】  
  device_nowMonitorDetail: "frame/searchCurrentFrame", //实时监控【详情】  
  device_nowMonitorSelect: "equipment/searchCurrentStatus", //实时监控【下拉框】
  
  device_positionList: "positioningModule/searchModule", //实时监控【列表】
  device_positionDelete: "positioningModule/deleteModule", //实时监控【删除】

  device_mapShow: "map/listModel", //地图

  device_nowStatisticsList: "equipment/searchStatistics", //实时统计【列表】  
 
  device_historyFrameSelect: "frame/getFrameType", //历史记录【帧类型、指令类型下拉框】
  device_historyList: "frame/searchHistoryFrame", //历史记录【列表】   
  device_historySelect: "frame/selectHelpMessage", //历史记录【显示所有求助信息】 
  device_historyHelp: "frame/handle/handleHelpFrame", //历史记录【处理求助信息】   
  device_historyDelete: "frame/delete/removeHistoryFrame" //历史记录【删除】   
};

export default apis;