import axios from 'axios';
import api from '@/assets/api/index.js';
import store from './index';
import {menu} from '@/assets/utils/dealMenu.js';
import router from '@/router'

let actions = {
  "INIT_LOGIN": function(obj,payload){
    let {commit} = obj;
    sessionStorage.setItem("zhihui", JSON.stringify(payload));
    commit("GET_LOGININFO", payload);
  },
  "INIT_MENU": function(obj,payload){
    let {commit} = obj;
    // let whereData = { "userId": "" };
    // if(payload){
    //   whereData.userId = payload;
    // } else {
    //   whereData.userId = store.state.loginInfo.token;
    // }    
    axios.post(api.apis.menuListApi, {}).then(res=>{
      if(res.state===200){
        let result = JSON.stringify(res.data);
        commit("GET_MENU", menu(result));
      } else {
        commit("GET_MENU", []);
      }
    })    
    // let result = JSON.stringify(router.options.routes);
    // commit("GET_MENU", menu(result));
  },
  pushCancel({commit}, cancel){
    commit('PUSH_CANCEL', cancel)
  },
  clearCancel({commit}){
    commit('CLEAR_CANCEL');
  },
  "SAVE_CURR_PERMISSIONS": function({commit}, payload){
    commit("GET_PERMISSIONS", payload);
  },
  "INIT_HISTOURY": function({commit}){
    return new Promise((resolve, reject)=>{
      axios.post(api.apis.device_historySelect, {}).then(res=>{
        var result = [];
        if(res.state==200){
          result = res.data.length==0 ? [] : res.data;
          console.log("GET_HISTOURYDATA", res)
          commit("GET_HISTOURYDATA", result);
        }
        resolve(result);
      }).catch(err=>{
        reject(err)
      });
    })
  },
  "UPDATE_HisDrawer": function({commit}, payload){
    commit("UPDATE_DRAWER", payload);
  }
};

export default actions;