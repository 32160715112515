//全局组件注册
import myTable from './index.vue';

export default  {
    install: function (Vue) {
        Vue.component('myTable', myTable)
    }
}


