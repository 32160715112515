// 仪器信息
const apis = {
  instrument_deviceInputAdd1: "equipment/info/addMonitor", //设备录入【新增-第一次录入】
  instrument_deviceInputAdd2: "equipment/info/addDeviceCustomer", //设备录入【新增-第二次录入】
  // instrument_deviceInputUpdate: "instrument/updateAgent", //设备录入【修改】
  instrument_deviceInputList: "equipment/info/searchDeviceInfo", //设备录入【列表】
  instrument_deviceInputDelete: "equipment/info/removeDeviceInfo", //设备录入【删除】
  instrument_deviceInputDownload: "equipment/downloadEquipmentExcel" //设备录入【下载】
  
};

export default apis;