<template>
  <el-button type="primary" plain icon="el-icon-refresh" size="mini" @click="resetQuery" ref="resetBtn">重置</el-button>
</template>

<script>
export default {
  name: "resetBtn",
  props: {
    formName: {type:String, default: "searchForm"}
  },
  methods: {
    resetQuery(){
      // resetFields	对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs.resetBtn.elForm.resetFields(); //清空表单
      this.$emit("reset")
    }
  }
}
</script>

<style>

</style>