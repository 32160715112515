let mutations = {
  "GET_LOGININFO": (store,payload) => {
    store.loginInfo = payload;
  },
  "GET_MENU":(store,payload) => {
    store.menuList = payload;
  },
  PUSH_CANCEL(state, cancel){
    state.axiosCancelArr.push(cancel.cancelToken);
  },
  CLEAR_CANCEL(state){
    state.axiosCancelArr.forEach(e=>{
      e && e()
    });
    state.axiosCancelArr = []
  },
  "GET_PERMISSIONS": (state, payload)=>{
    state.permissions = payload;
  },
  "GET_HISTOURYDATA": (state, payload)=>{
    state.messageData = payload;
  },
  "UPDATE_DRAWER": (state, payload)=>{
    state.historyDrawer = payload;
  },
};

export default mutations