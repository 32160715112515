import axios from 'axios'
import router from '@/router'
import store from '@/store'
import {Message,MessageBox,Loading}  from 'element-ui';
import {getCookie}  from '@/assets/utils/cookie';
import api from '@/assets/api/index'

let loading = null; //loading效果
let cancelNum = 0,responseNum=0; //请求失败次数，responseNum响应失败个数


//请求拦截器
axios.interceptors.request.use(function (config) {
  config.cancelToken = new axios.CancelToken((cancel) => {
    //使用vuex 定义pushCancel，请求进来存入
    store.dispatch('pushCancel', {cancelToken:cancel})
  });
  // 在发送请求之前做些什么(将数据发送给后台时的请求操作，可以给请求头添加token等信息)
  loading = Loading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  
  // console.log("接口请求",config)
  //判断是不是登录接口，如果不是，则请求参数添加请求头 。注意文件上传的请求头设置，文件上传不属于axios请求
  if(config.url != api.apis.login) {
    config.headers["token"] = store.getters.loginInfo.token;  
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});


// 响应拦截器
axios.interceptors.response.use(function (response) {
  // console.log("响应",response,router)  
    if(loading !== null) {
      loading.close()
      loading = null;
    }
    // 对响应数据做点什么
    if(response.data.state === 200 && response.status === 200){
      return response.data;
    } 
    //比如二进制流
    else if(!response.data.state && response.status === 200){
      return response;
    }
    else if(response.data.state == 5000){
      responseNum++;  //只要登录信息过期就+1，只要有一个“登录过期”就跳转
      if( responseNum==1 ){
        MessageBox.alert('登录信息已过期，请重新登录', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            router.replace("/login");
          }
        });
      }
    }
    else {
      //0001:系统异常，请联系管理员；0002：没有查询到结果
      Message.error(response.data.message);
    }
}, function (error) {
    // 对响应错误做点什么
    if(loading !== null) {
      loading.close()
      loading = null;
    }
    if( !axios.isCancel(error) ){ cancelNum++; }
    console.log("响应错误err",cancelNum,error, error.response, error.message)  
    if(error.response.status == 401){
      if( cancelNum==1 ){
        MessageBox.alert('登录信息已过期，请重新登录', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            router.replace("/login");
          }
        })
      } else { 
        return new Promise(() => {}); 
      }
    } else if(error.response.status == 500){
      Message.error("系统参数错误，请联系管理员！")
    }else {
      console.log(1)
      Message.error("系统异常，请联系管理员！")
    }
    return Promise.reject(error);
});


export default axios;