// 基础数据
const apis = {
  basicData_customertypeAdd: "customer/addCustomerType", //客户类型管理【新增】
  basicData_customertypeUpdate: "customer/updateCustomerType", //客户类型管理【修改】
  basicData_customertypeList: "customer/searchCustomerType", //客户类型管理【列表】
  basicData_customertypeDelete: "customer/removeCustomerType", //客户类型管理【删除】
  
  basicData_customerAdd: "customer/addCustomer", //客户管理【新增】
  basicData_customerUpdate: "customer/updateCustomer", //客户管理【修改】
  basicData_customerList: "customer/searchCustomer", //客户管理【列表】
  basicData_customerDelete: "customer/removeCustomer", //客户管理【删除】
  basicData_customerSelect: "customer/selectCustomer", //客户管理【客户下拉】
  basicData_customerDownload: "customer/downloadCustomerExcel", //客户管理【下载】
  
  basicData_showInfoAdd: "display/addLiaison", //显示信息设置【新增】
  basicData_showInfoUpdate: "display/updateLiaison", //显示信息设置【修改】
  basicData_showInfoList: "display/searchLiaison", //显示信息设置【列表】
  basicData_showInfoDelete: "display/deleteLiaison", //显示信息设置【删除】
  basicData_showInfoSettingList: "display/showDisplayConfig", //显示信息设置【获取显示信息设置】
  basicData_showInfoSettingChange: "display/changeDisplayConfig" //显示信息设置【更改显示信息设置】
};

export default apis;