import './axios'
import basicData from './allApis/basicData';
import product from './allApis/product';
import device from './allApis/device';
import sale from './allApis/sale';
import agent from './allApis/agent';
import instrument from './allApis/instrument';
import testData from './allApis/testData';
import user from './allApis/user';

let BASE_URL = "/techsuniot"; //域名地址，production   location.origin
let WS_URL = ""; //webSocket地址

if(process.env.NODE_ENV == "development"){
  BASE_URL = "http://192.168.9.179:8081/techsuniot" //---replace http://192.168.9.179:8081/techsuniot  http://ip/域名
  WS_URL = "ws://ip/域名";  //---replace
} else {
  WS_URL = "wss://"+location.host+"/techsuniot";
}
//处理接口地址
function dealApis(obj){
  for(var i in obj){
    obj[i] = BASE_URL +"/"+ obj[i];
  }
  return obj;
}

let apis = {  
  login: BASE_URL + '/user/login', //登录
  updateSelfPassword: BASE_URL + "/user/changePassword", //修改个人密码
  menuListApi: BASE_URL + "/user/getUserMenu", //用户菜单【导航栏】

  device_nowMonitorShowHelpWs: WS_URL + "/helpMessage/", //实时监控【显示求助信息websocket】  

  ...dealApis(device), //设备管理
  ...dealApis(product), //生产管理
  ...dealApis(sale), //销售管理
  ...dealApis(basicData), //基础数据管理
  ...dealApis(agent), //代理商信息
  ...dealApis(instrument), //仪器信息
  ...dealApis(testData), //测试数据统计
  ...dealApis(user), //用户管理
}
export default{apis}