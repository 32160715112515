<template>
  <el-container>
    <!-- 左菜单栏 -->
    <el-aside width="250px">
      <my-aside></my-aside>
    </el-aside>

    <!-- 主体部分 -->
    <el-container>
      <el-header>
        <vHeader></vHeader>
      </el-header>

      <el-main>
        <navMenu></navMenu>
        <!-- router-view 没有设置名字，那么默认为 default -->
        <router-view></router-view>
        <!-- <router-view name="helper"></router-view> -->
      </el-main>
    </el-container>

  </el-container>
</template>

<script>
import myAside from "./aside";
import vHeader from "./header";
import navMenu from "./navMenu";

export default {
  components:{ myAside, vHeader, navMenu}
}
</script>

<style lang="scss" scoped>
section.el-container{
  aside.el-aside{height:100%;background:#fff;}
  header.el-header{padding:0;height: 80px !important;;background: #F3F3F3;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);}
}
</style>