<template>
  <div id="mainSon">
    <router-view></router-view>
    <router-view name="helper"></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
#mainSon{width: 100%;height: 100%;}
</style>