<template>
  <div>
    <el-table :data="tableData" ref="table" class="myTable"
              border :header-cell-style="{background: '#E2F3FF', padding: '7px 0', color:'#2f2f2f'}" style="width:100%" 
              :cell-style ="cellStyle"
              :row-class-name="tableRowClassName"
              @selection-change="(val)=>{$emit('handleSelect',val)}" @sort-change="sortChange">
      <!-- 多选框 -->
      <el-table-column type="selection" width="40" v-if="selectShow" fixed></el-table-column>
      
      <!-- 表格内容 -->
      <el-table-column type="index" :index="indexMethod" width="80" label="序号" align="center" fixed></el-table-column>
      <template v-for="(info, infoIndex) in tableInfo">
        <el-table-column :key="infoIndex" :prop="info.prop" :label="info.label" :align="info.center?info.center:'center'" :fixed="info.fixed"
                         :min-width="info.minWidth" show-overflow-tooltip :sortable="info.sortable?'custom': false">
          <!-- <slot :name="info.prop" :data="info" :index="infoIndex"></slot> -->
          <!-- 给插槽命名、传值、序列号，使用插槽时记得传dealData -->
          <template slot-scope="scope">
            <slot v-if="info.dealData" :name="info.prop" :data="scope.row" :index="infoIndex"></slot>
            <span v-else>{{scope.row[info.prop]}}</span>
          </template>
        </el-table-column>
      </template>       

      <!-- 操作列，默认不需要，需要时父组件设置operateShow，操作列默认为200 -->
      <el-table-column v-if="operateShow" align="center" :min-width="operateWidth" fixed="right">
        <template slot="header" slot-scope="scope">
            <slot v-if="operateHeader" name="headerSearch" :data="scope.row"></slot>
            <span v-else>操作</span>
        </template>
        <template slot-scope="scope">
          <slot name="operate" :data="scope.row"></slot>
        </template>
      </el-table-column>    
    </el-table>

    <!-- 分页 -->
    <div class="pagination" v-if="paginationShow">
      <el-pagination background layout="total, prev, pager, next, jumper" 
        prev-text="上一页" next-text="下一页"
        :total="total" :page-size="pageSize" :current-page="pageNum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        ></el-pagination>
    </div>
      
  </div>
</template>

<script>
export default {
  props:{
    //是否有多个表格，默认false
    tableMore: { type: Boolean, require: false },
    //el-table组件绑定的数据
    tableData: { type: Array, require: true },
    //表格显示的字段和字段名称
    tableInfo: { type: Array, require: true },
    //操作栏是否显示，默认不显示
    operateShow: { type: Boolean, default: false },
    //操作栏宽
    operateWidth: { type: String, default: "200" },
    //多选框是否显示，默认不显示
    selectShow: { type: Boolean, default: false },
    //是否显示分页
    paginationShow: {type: Boolean, default: true},
    //页码
    pageNum: { type: Number, require: true, default: 1 },
    pageSize: { type: Number, require: true, default: 10 },
    //页码总数
    total: { type: Number, require: true, default: 0 },
    //操作栏是否添加筛选框
    operateHeader: { type: Boolean, default: false },
    //单元格样式，默认没有，如果有，需要父组件有cellStyle函数及参数，具体使用方法参考文档，与文档一致
    cellStyleShow: {  type: Function, default:()=>{} },
    //行样式
    tableRowClassFn:{ type: Function, default:()=>{} }
  },
  data() {
    return {
    }
  },
  
  methods: {
    //表格自定义序号
    indexMethod(index) {
      return (this.pageNum-1)*this.pageSize +(index+1) ;
    },
    // 行样式  父组件使用 :tableRowClassFn="父组件自定义函数名"，父组件获取到的常用参数：{row,rowIndex}
    tableRowClassName(row){
      return this.tableRowClassFn(row);
      /* if(row.online == 1){
        return 'warning-row';
      } else {
        return ''
      } */
    },
    //单元格样式 父组件使用 :cellStyleShow="父组件自定义函数名"，父组件获取到的常用参数：{ row, column, rowIndex, columnIndex }
    cellStyle(data){
      return this.cellStyleShow(data);
      // if(row.date == '2016-05-01' && columnIndex == 1){
      //   console.log(1)
      //   return {background: "#f00",color:"#fff"}
      // }
    },
    //当表格的排序条件发生变化的时候会触发该事件-后端排序
    sortChange(column ){
      this.$emit("handleSortChange", column);
    },

    //分页
    //每页多少条--常与选多少条的数组[10,20,30]配合使用
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      if(this.tableMore){
        this.$emit("sizeChange",val);
      } else {
        this.$parent.searchForm.pageSize = val;
        this.$parent.initData(1);
      }
    },
    //页码改变---只有单个表格不需要自己在页面上手动写
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`, this.$parent);
      if(this.tableMore){
        this.$emit("currentChange",val);
      } else {
        this.$parent.searchForm.current = val;
        this.$parent.initData(val);
      }
    }
    
  }
}

/**使用说明： 
 * 1. 普通表格用法：<my-table :tableData="tableData" :tableInfo="tableInfo" :total="total"></my-table>
 * （1）参数：
 * searchForm:{ //请求参数，要注意searchForm.pageNum不要更改名称，否则会引起错误
        devId:'',
        pageNum:1,
        pageSize:10
      },
      total: 0, //页码需要的参数
      tableData: [], //后台传过来的数据
      tableInfo: [
        {key: "firstLabelQr", value: "首QR", "minWidth": 180},
        {key: "lastLabelQr", value: "末QR", "minWidth": 180},
        {key: "skuId", value: "SKU_ID", "minWidth": 160},
        {key: "skuName", value: "货物名称", "minWidth": 160},
        {key: "totalNum", value: "标签总数", "minWidth": 90}
      ]
    （2）初始化请求数据：initData(1)
    initData(pageNum){ //参数用于table组件，父级页面使用该函数时不需要传参
      this.searchForm.pageNum = pageNum//这行语句必填。
      this.$axios.post(this.$api.listQrIntegral, this.searchForm).then( res =>{
        this.tableData = res.datas.records
        this.total = res.datas.total
      })
    }
    
    2. 表格中的某一列数据需要处理：
    <my-table :tableData="tableData" :tableInfo="tableInfo" :total="total">
      <template slot="字段名" scope-slot="自定义名称">
        <span>{{自定义名称.data.字段名}}</span>
      </template>
    </my-table>
    其中：{{自定义名称.data}}可以获取到表格中有关这一项的所有字段及字段值。
    （1）参数的改变：给要修改的字段添加  dealData: true
    tableInfo: [
        {key: "firstLabelQr", value: "首QR", "minWidth": 180},
        {key: "lastLabelQr", value: "末QR", "minWidth": 180},
        {key: "skuId", value: "SKU_ID", "minWidth": 160, dealData: true},
      ]
    （2）初始化请求数据：与1相同
    
    3. 带有操作项的表格：
    <my-table :tableData="tableData" :tableInfo="tableInfo" :total="total" operateShow operateWidth="130">
      <template slot="operate" scope-slot="自定义名称">
        <el-button type="warning" size="mini" icon="el-icon-edit-outline" 
                    @click="openDialog('updateForm', 自定义名称.data)"
        >修改</el-button>
      </template>
    </my-table>
    （1）引入公共方法 ：import { pageNum } from "@/filters/common";
    （2）参数与 1 相同；
    （3）初始化请求数据与 1 相同；
    （4）这里有个 pageNum 的方法，主要是用来停留表格页面的，常用在表格的新增、修改、删除等操作；
    使用方法： initData( pageNum("操作类型", this.total, this.searchForm.pageSize) ); 
    操作类型暂时有："add","update","delete"，对应相关操作。this.searchForm.pageSize可不传，默认为10
    例如这里的：openDialog(formName, params){
      console.log("删除了");
      initData( pageNum("delete", this.total) ); 
    }
*/
</script>

