<template>
  <div id="login">
    <!-- 主体部分 -->
    <div class="login-main">
      <!-- 左边 -->
      <div class="main-form">
        <h2>
          <!-- <img :src="logo" alt="" srcset=""> -->
          速创仪器远程管理平台
        </h2>
        <h3>登录</h3>

        <el-form label-position="top" :model="loginForm" :rules="rules" ref="ruleForm" class="loginForm" size="mini" :hide-required-asterisk="true">
          <el-form-item label="用户名" prop="userAccount">
            <el-input v-model="loginForm.userAccount" v-focus  ref="userName" placeholder="请输入用户名"
                      @keyup.enter.native="confirmInput($event,false)"                
            ><i v-show="yesShow" slot="suffix" class="el-input__icon el-icon-my-yes"></i></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="userPwd">
            <el-input v-model="loginForm.userPwd" show-password ref="passWord" placeholder="请输入密码"
                      @keyup.enter.native="confirmInput($event,true)"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <!-- <el-checkbox v-model="checked">记住密码</el-checkbox> -->
          </el-form-item>
          <el-form-item align="center">
            <el-button type="primary" size="small" @click="loginEnter()">登录</el-button>
            <el-button type="info" size="small" style="margin-left:18%" @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 右边 -->
      <div class="main-img">
        <h1></h1>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  watch:{
    "$store.getters.menuList": function(newValue){
       this.$router.replace(newValue[0].path);
    }
  },
  data(){
    var checkUserName = (rule, value, callback) => {
      this.yesShow = false;
      if (value.trim() == "") {
        callback(new Error('必填项不能为空'));
      } 
      // else if(value.trim().length<6 || value.trim().length>20){
      //   callback(new Error('长度在 6 到 20 个字符'));
      // } 
      else {
        this.yesShow = true;
        callback();
      }
    };
    return {
      // logo: require("@/assets/imgs/login/logo.png"),
      yesShow:false,
      loginForm: {
        userAccount: "",
        userPwd: ""
      },
      checked: false,
      rules: {
        userAccount: { validator: checkUserName, trigger: ['blur', 'change'] },
        // userAccount:[{ required: true, message: '必填项不能为空', trigger: 'blur' },
        //           { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }],
        userPwd:[{ required: true, message: '必填项不能为空', trigger: 'blur' }]
                  // { pattern:/^([a-zA-Z0-9])(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9_])[\S]{7,16}$/, message: '8~16个字符，且不能以特殊字符开头', trigger: ['blur', 'change'] }]
      }
    }
  },
  directives:{
    focus: {
      inserted: function(el){
        el.children[0].focus();
      }
    }
  },
  methods:{
    confirmInput(e, isLogin){
      // 判断输入框是否有值，有的话失焦让另外一个输入框聚焦；
      // 没有就先失焦让提示显示出来，再重新聚焦（这样可以让element表单封装的验证信息显示出来）
      if(e.target.value !=""){
        e.target.blur();
        this.inputFocus(isLogin);
      } else {
        e.target.blur();
        e.target.focus();
      }
    },

    //通过isLogin这个参数来判断按下enter键后是否要调用登录接口
    inputFocus(isLogin){
      if(isLogin){
        this.loginEnter();
      } else {
        this.$refs.passWord.focus();
      }
    },

    //登录
    loginEnter(){
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let userAccount = this.loginForm.userAccount;
            // let userPwd = md5(this.loginForm.userPwd).toUpperCase();
            this.login(userAccount, this.loginForm.userPwd); //调用登录接口
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },

    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    //调用登录接口userLogin
    login(name, pwd){
      this.$axios.post(this.$api.login,{
        username: name,
        password: pwd
      }).then(res =>{
        if(res.state==200){
          // sessionStorage.setItem("liduInfo", JSON.stringify(res.data));
          this.$store.dispatch("INIT_LOGIN",res.data); //修改存放在store中的login
          this.$store.dispatch("INIT_MENU"); //加载路由
          // this.$store.dispatch("INIT_HISTOURY"); //获取历史求助信息
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/public.scss";
#login{ 
  width:100%;height: 100%;min-height: 540px;
  background: url("~@/assets/imgs/login/bg.png") no-repeat center; background-size: 100% 100%;

  .login-main{
    width: 70%;height: 67%; min-width: 440px; min-height: 540px;background:#fff;
    position: absolute; left: 0;right:0;top:0;bottom:0; margin:auto;  z-index:2;
    display: flex;
    -moz-box-shadow: 2px 12px 12px 2px rgb(0 0 0 / 30%);
    -webkit-box-shadow: 2px 12px 12px 2px rgb(0 0 0 / 30%);
    box-shadow: 2px 12px 12px 2px rgb(0 0 0 / 30%);
    img{display: inline-block;}
    .main-form{
      width:40%;height: 100%;
      h2{margin-top:11.4%;text-align: center;font-size:36px;color:#1583F3;}
      h3{margin-top:13.2%;color:$--color-primary;font-size: 26px;font-weight:400;text-align: center;}
      .loginForm{width:81%;margin: 12.2% auto;}
    }
    .main-img{flex:1;height: 100%;background: url("~@/assets/imgs/login/banner.png") no-repeat center;background-size: 100% 100%;}
  }
  .login-footMsg{position: fixed;left: 0;right:0; text-align: center;bottom:54px; font-size:14px;color:#fff;}

  .el-icon-my-yes{ background: url("~@/assets/imgs/icons/yes.png") center no-repeat; }
  .el-icon-my-yes:before{
    content: "\8d3a";
    font-size: 14px;
    visibility: hidden;
  }
}
</style>