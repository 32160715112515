// 测试数据统计
const apis = {
  testData_deviceDetail: "statistics/searchTestHistory", //统计仪器、某一项目、指定项目【详情】  

  testData_deviceList: "statistics/searchDeviceIdStatistics", //统计仪器【列表】
  testData_deviceExport: "statistics/downloadDeviceIdExcel", //统计仪器【导出】  

  testData_oneProjList: "statistics/searchTestProjectStatistics", //统计某一项目【列表】  
  testData_oneProjExport: "statistics/downloadTestProjectExcel", //统计某一项目【导出】 

  testData_appointProjList: "statistics/searchBothStatistics", //统计指定项目【列表】  
  testData_appointProjExport: "statistics/downloadBothExcel" //【详情】  
};

export default apis;