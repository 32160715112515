<template>  
  <div id="breadcrumb">
    <!-- 顶栏 -->
    <el-menu class="clear breadMain" :default-active="activeIndex2" router mode="horizontal" 
             background-color="#fff" active-text-color="#409EFF"
    >
      <template v-for="three in threeRouters">
        <template v-if="!three.meta.hidden">  
          <!-- 单个 -->
          <el-menu-item v-if="three.children==undefined || three.children.length == 0" :key="three.name" :index="three.path">{{three.meta.title}}</el-menu-item>

          <!-- 多个 -->
          <el-submenu v-else :key="three.name" :index="three.path">
            <template slot="title">{{three.meta.title}}</template>
            <template v-for="four in three.children">
              <el-menu-item  :index="four.path" :key="four.name">{{four.meta.title}}</el-menu-item>
             </template>
          </el-submenu>
        </template>
      </template>

    </el-menu>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed: mapGetters(["menuList"]),
  data(){
    return {
      activeIndex2: "",
      // menuList: this.$router.options.routes, //获取所有菜单
      threeRouters: []
    }
  },
  watch:{
    "$route":{
      handler: function(newV,oldV){
        // console.log("监听面包屑",newV);
        this.activeIndex2 = newV.path; //建议放到路由监听中，直接初始赋值可能会有缓存
        this.dealRoute(newV);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    dealRoute(currRoute){
      // console.log("当前路由",currRoute)
      if(currRoute.matched.length == 2){
        this.threeRouters = [currRoute]; //直接将当前的路由信息传给组件
      }
      else if(currRoute.matched.length == 3){ //说明有3级路由
        this.menuList.map(item=>{
          if(item.path == currRoute.matched[0].path){
            //二级路由。存所有三级路由的数据
            item.children.map(twoItem =>{
              if(currRoute.matched[1].name == twoItem.name){
                //meta中含有fromMenu的路由属于4级路由，4级路由在路由配置中需要多一个route-view。
                //这里的配置是把四级路由当做三级路由配置，因此导航栏我们需要根据fromMenu来给三级路由添加对应的四级路由菜单
                var result = JSON.parse(JSON.stringify(twoItem.children.filter(item=>!item.meta.fromMenu)));
                var fromMenuArr = JSON.parse(JSON.stringify(twoItem.children.filter(item=>item.meta.fromMenu)));
                
                //把四级菜单组合到三级菜单中
                result.map(resultItem=>{
                  resultItem.children = [];
                  fromMenuArr.map(fromMenuItem=>{
                    if(resultItem.path == fromMenuItem.meta.fromMenu){
                      resultItem.children.push(fromMenuItem);
                    }
                  })
                })
                this.threeRouters =JSON.parse(JSON.stringify(result));
              }
            });
 
          }
        })
      } //判断结束currRoute.matched.length=3
      // console.log("3ji菜单",currRoute)
    },
    handleSelect(key, keyPath) {
      console.log("",key, keyPath);
    },
  },
  created(){ 
    
  }
}
</script>

<style>

</style>