<template>
  <div id="myAside">
    <h2 class="valign"> <img :src="logo" alt="" srcset=""> </h2>
    
      <!-- active-text-color="#D50000" -->
    <el-menu 
        id="myAside-menu"
        router
        :default-active="$route.matched[1].path"
        active-text-color="#409EFF"
    >
      <!-- 侧导航栏 -->      
      <template v-for="(item,itemId) in menuList">
        <!-- 节点不隐藏 -->
        <template v-if="!item.meta.hidden">
          <!-- 是否只有一个节点 菜单下有一个children。
          另外，children下还有children为三级菜单，这种将会显示二级菜单。
          否则根据菜单的配置，只显示一级菜单和多个三级菜单（即多个面包屑），而不显示二级菜单，
          这是不合理，因为你的路由配置的限制，区分不了这种效果，一次，
          这里只要判断children.length==1就行了 -->
          <template v-if="item.children.length==1">
            <el-menu-item :index="item.redirect" :key="itemId" class="oneLeaf">
              <i :class="'icon-'+item.name"></i>
              <span slot="title" class="oneLeftTitle ml10">{{item.meta.title}}</span>
            </el-menu-item>
          </template>

          <!-- 多个节点 -->
          <el-submenu v-else :index="item.redirect" :key="itemId" class="moreLeaf">
            <template slot="title">
              <i :class="'icon-'+item.name"></i>
              <span class="oneLeftTitle ml10">{{item.meta.title}}</span>
            </template>
 
            <el-menu-item-group>
              <template v-for="(child,childId) in item.children">       
                <template v-if="!child.meta.hidden">                  
                  <!-- <el-menu-item :index="item.path +'/'+ child.path" :key="childId"> -->
                  <el-menu-item :index="child.path" :key="childId">
                    {{child.meta.title}}
                  </el-menu-item>  
                </template>
                <!-- 判断是否为3级菜单结束-->
 
              </template>
            </el-menu-item-group>
 
          </el-submenu>
        </template>
 
      </template>
 
    </el-menu>
    <!-- 导航栏结束 -->

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed: mapGetters(["menuList"]),
  data(){
    return {
      // menuList: this.$router.options.routes,
      logo: require("@/assets/imgs/logo.png")
    }
  }
}
</script>

<style lang="scss" scoped>
$iconArr: basicData,product,device,sale,agent,instrument,testData,userManage;

#myAside{
  border-right:0;
  h2 {
    height:80px;text-align: center;
    border-bottom: 1px solid #E4E4E4;
    img{display: inline-block; height: 58px; object-fit: cover;}
  }  
  @each $item in $iconArr {
    $i:index($iconArr,$item); // 获取 $c 在数组中的索引，并赋值给 $i 赋值用冒号，不是等号~！
    .icon-#{$item}{ display: inline-block;width:24px;height:24px; }
    .icon-#{$item}{ background: url("~@/assets/imgs/aside/#{$item}.png") center no-repeat; }
    .icon-#{$item}:before{ content: "\8d3a"; font-size: 22px; visibility: hidden; }
    .el-submenu.is-active, .el-menu-item.is-active{
      .icon-#{$item}{ background: url("~@/assets/imgs/aside/#{$item}_a.png") center no-repeat; }  
    }
  }

}
</style>