// import Nav from "@/components/NavMenu"
// import NotFound from '@/components/notFound'
import myLayout from '@/components/myLayout';
import Breadcrumb from '@/components/myLayout/navMenu';
import NotFound from '@/components/pages/notFound.vue'

import router from "@/router"
import {createRouter} from "@/router"
import Vue from 'vue'


function _import(str) { // views文件夹下的Home组件，传入的格式为 'Home'
  return function (resolve) {
    require([`@/views${str}.vue`], resolve);
  };
}
const menu = function (getArr) {
  console.log("后台返菜单",JSON.parse(getArr))
  var result = [], arr = [] ; //result转换成路由的结构.arr深浅呗-转-浅拷贝。
  
  if(getArr.length !=0){ arr = JSON.parse( getArr ); result = recurseFn(arr); }
  // if(getArr.length !=0){ arr = JSON.parse( getArr ); result = arr; }
  result.push({ 
    path: "*",
    name: "notFound",
    meta: { hidden: true },
    component: NotFound
  })
  result.unshift({
    path: '/',
    name: "home",
    meta: {hidden: true,title: "主页"},
    redirect: result[0].path
  })

  router.matcher = createRouter().matcher;
  // router.addRoutes(result);
  for(var i=0; i<result.length; i++){
    router.addRoute(result[i]);
  }
  console.log("处理完成",result)
  return result;
}

//递归处理数据
function recurseFn (nodes=[], arr=[]){
  nodes.forEach(item=>{
      //只有一个节点：一级节点下只有一个子元素（即页面信息，二级路由），二级路由下没有子节点。leaf：true只有一个节点
      // "leaf": item.children.length==1 && !item.children[0].children.length ? true: false, 
      arr.push({
        "path": item.menuUrl,
        "name": item.menuUrl.substr( 1, item.menuUrl.length ),
        "meta": {hidden: false, title: item.menuName},
        "component": myLayout,
        "redirect": item.children[0].menuUrl,
        "children": recurseChild(item.children)
      });     
  })
  return arr;
}

function recurseChild(item, result=[]){ //二级及二级以后的路由,childItem.children3级路由  
  // console.log("二级",item)
    item.map( (childItem, childIndex) =>{  
      if(childItem.menuType == "网页"){              
        result.push({
          "path": childItem.menuUrl,
          "name": ( childItem.menuUrl.match(/\w+\/\w+$/) )[0].split("/").join("_"),
          "meta": {hidden: false, title: childItem.menuName, buttons: childItem.meta.buttons},
          "component": _import(childItem.menuUrl)
        })
        if(childItem.children && childItem.children.length){
          result[childIndex].children = recurseChild(childItem.children);
        }
      } else if(childItem.menuType == "按钮") {

      }
      

    })
  return result;
}  

export {menu};