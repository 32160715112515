// 代理商信息
const apis = {
  agent_listAdd: "agent/addAgent", //代理商信息【新增】
  agent_listUpdate: "agent/updateAgent", //代理商信息【修改】
  agent_listList: "agent/searchAgentInfo", //代理商信息【列表】
  agent_listDelete: "agent/removeAgent", //代理商信息【删除、批量删除】
  agent_listSelect: "agent/selectAgentInfo", //代理商信息【下拉】
  agent_listDownload: "agent/downloadAgentExcel" //代理商信息【下载】
  
};

export default apis;