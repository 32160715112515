<template>
  <div id="vhead">
    <el-row>
      <el-col :span="8" class="left">
        <h3>速创仪器远程管理平台</h3>
      </el-col>
      <el-col :span="16" class="right flex justifyEnd alignCenter">
          <p>修改密码</p>
          <img :src="password" alt="" class="pointer" style="margin:0 40px 0 10px;" @click="openPwdDialog">
          <p>Hi</p>
          <span><span v-show="username">，</span>{{username}}</span>
          <!-- <el-badge :is-dot="isDot" class="item"><img :src="user" alt="" class="pointer" style="margin-left:15px;" @click="openHistory()"></el-badge> -->
          <el-badge :max="99" :value="dotVal" class="item">
            <img :src="user" alt="" class="pointer" style="margin-left:15px;" @click="openHistory()">
          </el-badge>
          <p class="line"></p>
          <img :src="logout" alt="" class="pointer" style="margin-right:48px;" @click="loginOut()">
      </el-col>
    </el-row>

    <!-- 修改密码 -->
    <el-dialog title="修改个人密码" width="500px" :visible.sync="passwordDialog" v-if="passwordDialog" class="dialogStyle" :close-on-click-modal="false">
      <el-form ref="addForm" :model="addForm" :rules="formRules" label-width="80px" size="mini">
        <el-form-item label="旧密码" prop="rawPassword">
          <el-input type="password" v-model="addForm.rawPassword" placeholder="请输入旧密码" :maxlength="64" show-password autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" v-model="addForm.newPassword" placeholder="请输入新密码" :maxlength="64" show-password autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="addForm.checkPass" placeholder="请确认密码" :maxlength="64" show-password autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="passwordDialog = false">取 消</el-button>
        <el-button type="primary" size="small" @click="updatePassword()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 点击弹开历史纪录 -->
    <el-drawer :title="'求助信息（'+messageData.length+'）'" :visible.sync="historyDrawer" direction="rtl" :before-close="handleCloseDrawer">      
      <ul class="historyMsg" >
        <template v-if="messageData && messageData.length">
          <li v-for="(msg, index) in messageData" :key="msg.id">
            <p><strong>仪器ID：</strong>{{msg.deviceId}}</p>
            <p><strong>求助人姓名：</strong>{{msg.helperName}}</p>
            <p><strong>求助人职位：</strong>{{msg.position}}</p>
            <p><strong>联系电话：</strong>{{msg.phoneNumber}}</p>
            <p><strong>上报时间：</strong>{{msg.time|dateTimeFormat}}</p>
            <div class="btns">
              <el-link type="primary" class="ml10" @click="deleteMessageData(msg.id, index)">处理</el-link>
            </div>
          </li>
        </template>
        <template v-else>
          <li class="noMsg">暂无记录</li>
        </template>
      </ul>

    </el-drawer>
  </div>
</template>

<script>
// import {mapState} from 'vuex'
export default {
  inject: ['reload'],
  data(){
    var validatePass = (rule, value, callback) => {
      if ( !value ) {
        callback(new Error('请输入新密码'));
      } else {
        if (this.addForm.checkPass !== '') {
          this.$refs.addForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if ( !value ) {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.addForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      username: this.$store.getters.loginInfo.username,
      user: require("@/assets/imgs/icons/user.png"),
      logout: require("@/assets/imgs/icons/logout.png"),
      password: require("@/assets/imgs/icons/password.png"),
      passwordDialog: false,
      historyDrawer: this.commonInfo,
      isDot: false, //小圆点是否显示
      dotVal: 0,
      messageData: [],
      websock: null, //websocket求助信息
      dealHelpId: [], //处理求助信息的id      
      addForm:{},
      formRules:{
        rawPassword: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
        newPassword: [{ validator: validatePass, required: true, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, required: true, trigger: 'blur' }]
      },
      timer: null
    }
  },
  // computed: mapState(["messageData"]),
  methods:{
    //打开密码弹框
    openPwdDialog(){
      this.addForm = {
        newPassword: "",
        rawPassword: "",
        checkPass: ""
      }
      this.passwordDialog = true;
    },
    //修改密码
    updatePassword(){
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          this.utils.handleconfirm("确定要修改密码吗？").then(()=>{
            this.$axios.post(this.$api.updateSelfPassword, {
              "newPassword": this.addForm.newPassword,
              "rawPassword": this.addForm.rawPassword
            }).then((res)=>{
              if(res.state==200) {
                this.passwordDialog = false;
                this.$message.success("密码修改成功！");
              }
            })
          }).catch(()=>{
            this.$message.info('已取消操作！')
          })
        } else {
          return false;
        }
      })
    },

    //退出登录
    loginOut(){
      this.utils.handleconfirm("确定要退出登录吗？").then(()=>{
        sessionStorage.clear(); //清空缓存
        this.$router.replace("/login");
      }).catch(()=>{
        this.$message.info('已取消操作！')
      })
    },

    //提示
    tipsOpenHistory(){
      this.utils.handleconfirm("您有求助信息未处理，是否要去处理？").then(()=>{
        this.historyDrawer = true;
      }).catch(()=>{
        this.$message.info('已取消操作！')
      })
    },
    //打开历史纪录
    openHistory(){
      this.historyDrawer = true;      
      this.getHistoryData();
    },
    getHistoryData(tips){
      this.$axios.post(this.$api.device_historySelect, {}).then(res=>{
        this.messageData = res.data;
        if(res.data.length){ 
          this.isDot = true;
          // this.tipsOpenHistory(); 
        }
        this.dotVal = res.data.length;
        if(!res.data || !res.data.length) this.isDot = false;
      });
      // this.$store.dispatch("INIT_HISTOURY").then(res=>{
      //   console.log("历史信息",res)
      //   if(res.length){ 
      //     this.isDot = true;
      //     this.dotVal = res.length;
      //     // this.tipsOpenHistory(); 
      //   }
      //   if(!res || !res.length) this.isDot = false;
      // });
    },

    //删除 历史求助信息
    deleteMessageData(id, index){
      this.dealHelpId.push( id );
      this.messageData.splice(index, 1);
    },
    //退出调用接口处理信息
    handleCloseDrawer(){
      if(this.dealHelpId.length){
        this.$axios.post(this.$api.device_historyHelp, {id: this.dealHelpId}).then(res=>{
          if(this.messageData.length ==0) this.isDot = false; //小圆点不现实
          this.$message.success("处理成功！");
          this.getHistoryData(); //处理成功后刷新求助信息列表
        })
      }    
      this.historyDrawer = false;    
    },

    //web Socket警示显示求助信息
    initWsShowHelp(){
      if(typeof(WebSocket) === "undefined"){
          alert("您的浏览器不支持socket")
      }else{
          // 实例化socket
          var url = this.$api.device_nowMonitorShowHelpWs+this.utils.commonInfo().stateToken;
          this.websock = new WebSocket(url);
          // 监听socket连接
          this.websock.onopen = this.open
          // 监听socket错误信息
          this.websock.onerror = this.error
          // 监听socket消息
          this.websock.onmessage = this.getMessage;
          // 销毁
          this.websock.onclose = this.closeSocket;
      }
      // window.addEventListener('onmessageWS', this.getSocketData)
    },
    //连接建立之后执行send方法发送数据
    open: function(){
      console.log("socket连接成功");  
      clearTimeout(this.timer);    
    },
    error: function (err) {
      console.log("连接错误",err);
      var that = this;
      clearTimeout(that.timer);
      that.timer = setTimeout(function(){
        console.info("连接错误，尝试重连...");
        that.initWsShowHelp();
      }, 1000*60);
    },
    getMessage: function (msg) {
        // console.log("消息",msg.data)
        // this.showMsg(JSON.parse(msg.data));        
        if(JSON.parse(msg.data).state == 103) {
          this.isDot = true;
          this.showMsg(JSON.parse(msg.data));
        }
    },
    closeSocket: function (e) {
      //强制刷新页面也会断开连接，此时e为空，由于进入页面create已经重连了，所以这里要判断一下，否则会造成两次重连
      console.log("断开连接",e);
      var that = this;      
      // that.websock.close();
      clearTimeout(that.timer);
      that.timer = setTimeout(function(){
        console.info("断开连接，尝试重连...");
        if(e) that.initWsShowHelp();        
      }, 1000*60);
    },
    showMsg(data){
      var that = this;
      
      let html = `<p><strong>仪器ID：</strong>${data.data.deviceId}</p>
                  <p><strong>求助人姓名：</strong>${data.data.helperName}</p>
                  <p><strong>求助人职位：</strong>${data.data.position}</p>
                  <p><strong>联系电话：</strong>${data.data.phoneNumber}</p>
                  <p><strong>上报时间：</strong>${data.data.time}</p>
                  <strong style="cursor:pointer;color:#409EFF">历史求助链接</strong>`
      // let html = `<strong style="cursor:pointer;color:#409EFF">历史求助链接</strong>`
      that.$notify.info({
        title: "求助信息",
        dangerouslyUseHTMLString: true,
        duration: 0,
        message: html,
        onClick: function(){
          if(that.$route.path=="/device/history"){
            // that.reload();
            that.$router.replace({
              path: "/device/history",
              query: {"showHelp":2}
            },()=>{
              that.reload();//刷新页面
            })
          } else {
            that.$router.push({ path: "/device/history", query:{"showHelp":2}})
          }
        }
      });
      that.getHistoryData(); //收到消息后刷新求助信息列表
    },
  }, 
  created(){
    this.initWsShowHelp(); //连接ws
    this.getHistoryData();
  },
  destroyed(){
    // 销毁监听
    // this.websock.onclose = this.closeSocket;
    this.websock.close();
  }
}
</script>

<style lang="scss" scoped>
#vhead{ 
  width:100%; height:100%;
  h3{margin-left:29px;font-size:24px;color:#1A86F6;line-height: 80px;}
  .right{
    height:80px;font-size:20px;color:#464646;
    img{display: block;}
    p.line{width: 1px; height: 40px; background: #D8BD92;margin:0 30px;}
  }

  ul.historyMsg{
    padding: 0 10px;
    border-top:1px solid #ccc;
    li{
      padding:4px 0;
      border-bottom:1px solid #ccc;
      position: relative;
      &.noMsg{padding:20px 0;}
      &:hover{background:#e9e9eb;}
      .btns{
        position: absolute;top: 4px;right: 10px;
        .el-link{ background: #fff; border-radius: 4px;}
      }
    }
  }
}
</style>