// 用户管理
const apis = {
  userManage_menuSelect: "user/manage/getRoles", //菜单管理【下拉】  
  userManage_menuList: "user/manage/searchMenu", //菜单管理【列表】  
  userManage_menuAdd: "user/manage/addMenu", //菜单管理【新增】   
  userManage_menuUpdate: "user/manage/updateMenu", //菜单管理【修改】  
  userManage_menuDelete: "user/manage/removeMenu", //菜单管理【删除】 

  userManage_roleSelect: "user/manage/getRoles", //角色信息【下拉】  
  userManage_roleList: "user/manage/searchRolePage", //角色信息【列表】  
  // userManage_roleList: "user/manage/searchRolePower", //角色信息【列表】  
  userManage_roleUpdate: "user/manage/addRoleMenu", //角色信息【修改】  
  userManage_roleDelete: "user/manage/removeRolePower", //角色信息【删除】  

  userManage_userList: "user/manage/searchUserInfo", //用户信息【列表】  
  userManage_userAdd: "user/manage/createUser", //用户信息【新增】 
  userManage_userUpdate: "user/manage/updateUser", //用户信息【修改】 
  userManage_userResetPwd: "user/manage/resetPassword", //用户信息【重置密码】
  userManage_userDelete: "user/manage/removeUser", //用户信息【删除】 

};

export default apis;