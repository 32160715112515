import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/components/pages/login.vue';
import Home from '@/components/pages/home.vue';

import MyLayout from '@/components/myLayout';
// import Breadcrumb from '@/components/myLayout/breadcrumb';
import Breadcrumb from '@/components/myLayout/navMenu';
import EmptyRouter from '@/components/myLayout/emptyRouter';

import Store from "@/store"
import {MessageBox}  from 'element-ui';


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: "home",
  //   meta: {hidden: true,title: "主页"},
  //   redirect: "/device"
  // },
  {
    path: '/login',
    name: 'Login',
    meta: {hidden: true},
    component: Login
  }
]

//重复点击选中的导航时报错问题
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return routerPush.call(this, location).catch(error => error);
}
const routerReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function (location) {
    return routerReplace.call(this, location).catch(error => error);
}

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

const createRouter  = () => new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL, //根
  // mode: 'history',
  routes
});
const router = createRouter();
export { createRouter }

router.beforeEach((to,from,next) => { //全局前置守卫
  //to: 将要进入目标的路由对象
  //form： 即将离开的目标路由对象
  //执行跳转的下一步钩子、
  
   //判断是否存在session值，若不存在表示没有登录。=={}说明登录值为空。有值说明已经登录过了，直接通过
  // if( Object.keys(Store.state.loginInfo).length==0 ){
  if(!sessionStorage.getItem("zhihui")){
    if(to.name != "Login"){ //判断当前页面是不是登录页、
      // MessageBox.confirm('您还未登录，请先去登录', '提示', {
      //   confirmButtonText: '确定',
      //   type: 'error',
      //   showClose: false,
      //   showCancelButton: false,
      //   closeOnClickModal: false
      // }).then(() => {
        next({name:"Login"});
      // });
    } else {
      next();
    }
  } else {
    if(to.name!="Login" && !from.name && from.path=="/" ){ Store.dispatch("INIT_MENU"); } //刷新时，to.name和from.name都为null
    Store.dispatch("SAVE_CURR_PERMISSIONS",to.meta.buttons); //报错每条路由信息的按钮权限
    next();
  }
  Store.dispatch('clearCancel'); //清空存入的信息。终止未请求成功的请求
})

export default router
