
import store from '@/store'

function getInnerText(element) {
  if (typeof element.innerText === 'string') {
      return element.innerText;
  } else {
      return element.textContent;
  }
}

export const btnShow = {
  bind: function(el, options){
    var buttons = Object.keys(options.value.meta).length==0 ?[]: options.value.meta.buttons;  
    var html = getInnerText(el); 

    if(buttons && buttons.length!=0){   

      if(buttons.some(item=>item==html)){
        el.style.visibility = "visible";
      } else {
        el.style.display = "none";
        
      }
    } else {
      el.style.display = "none";
    }
  },
  update: function(el, binding, vnode){
    // *** 当指令参数更改时，判断值若与前值不同，则代表使用了当前指令
    if (binding.oldValue == binding.value) {
      return
    }
  }
}
