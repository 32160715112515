import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; //elementUI的样式
// import '@/assets/css/element-variables.scss'
import '@/assets/css/style.css';  //自己的样式
import '@/assets/css/style.scss';  //自己的样式
Vue.use(ElementUI);

// import "@/assets/mock";
import axios from "axios";
Vue.prototype.$axios = axios;
import api from '@/assets/api' //引入接口，index.js可以省略
Vue.prototype.$api = api.apis;

import fil from '@/components/filter';
for (let key in fil.filters) {
  Vue.filter(key, fil.filters[key]);
}

import  * as directives from '@/components/directives';
for (let key in directives) {
  // console.log(directives)
  Vue.directive(key, directives[key])
}

import utils from "@/assets/utils/common.js"
Vue.prototype.utils = utils;

// 注册全局table组件
import myTable from '@/components/table/index.js'
Vue.use(myTable);
//重置按钮
import ResetBtn from '@/components/formElement/resetBtn.vue'
Vue.component('ResetBtn', ResetBtn)
// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar"
Vue.component('RightToolbar', RightToolbar)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
